// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-modal {
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 20px;
  min-height: 300px;
  margin: auto;
  background-color: #121212;
}

.login-modal h2 {
  text-align: center;
}

.login-modal label {
  margin-right: 10px;
  width: 80px;
  display: inline-block;
}

.login-modal .login-modal-input-container {
  margin-top: 10px;
}

.login-modal button {
  margin-left: 8px;
}

.login-modal .login-modal-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.login-modal .login-modal-status {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/login/LoginModal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".login-modal {\n  display: flex;\n  flex-direction: column;\n  width: 800px;\n  padding: 20px;\n  min-height: 300px;\n  margin: auto;\n  background-color: #121212;\n}\n\n.login-modal h2 {\n  text-align: center;\n}\n\n.login-modal label {\n  margin-right: 10px;\n  width: 80px;\n  display: inline-block;\n}\n\n.login-modal .login-modal-input-container {\n  margin-top: 10px;\n}\n\n.login-modal button {\n  margin-left: 8px;\n}\n\n.login-modal .login-modal-buttons-container {\n  display: flex;\n  justify-content: center;\n  margin-top: auto;\n}\n\n.login-modal .login-modal-status {\n  margin-top: 0px;\n  margin-bottom: 20px;\n  text-align: center;\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
