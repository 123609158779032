// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-modal {
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.47);
  display: flex;
  position: fixed;
}
`, "",{"version":3,"sources":["webpack://./src/controls/Modal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,qCAAqC;EACrC,aAAa;EACb,eAAe;AACjB","sourcesContent":[".input-modal {\n  width: 100vw;\n  height: 100vh;\n  z-index: 1000000;\n  background-color: rgba(0, 0, 0, 0.47);\n  display: flex;\n  position: fixed;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
