// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.midi-keyboard-vc {
  display: flex;
  flex-direction: column;
}

.midi-keyboard {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  height: 228px;
  user-select: none;

  * {
    user-select: none;
  }

  .octave-controls-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ffffff2d;
    border-right: none;
    letter-spacing: -3px;
  }

  .octave-changer-label {
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-align: center;
    width: 20px;
  }

  .octave-changer {
    display: flex;
    flex-direction: column;
    margin-right: 6px;

    button:nth-child(1) {
      margin-bottom: 1px;
    }

    button:nth-child(2) {
      margin-top: 2px;
    }

    button {
      flex: 1;
      width: 40px;
      font-size: 20px;
    }
  }

  .octave-offset-display {
    display: flex;
    justify-content: center;
    font-weight: bold;
    border-top: 1px solid #ffffff2d;
    margin-top: 3px;
    padding-right: 2px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/midiKeyboard/MidiKeyboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,iBAAiB;;EAEjB;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,2BAA2B;IAC3B,kBAAkB;IAClB,oBAAoB;EACtB;;EAEA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;;IAEjB;MACE,kBAAkB;IACpB;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,OAAO;MACP,WAAW;MACX,eAAe;IACjB;EACF;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,+BAA+B;IAC/B,eAAe;IACf,kBAAkB;EACpB;AACF","sourcesContent":[".midi-keyboard-vc {\n  display: flex;\n  flex-direction: column;\n}\n\n.midi-keyboard {\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n  flex-direction: row;\n  height: 228px;\n  user-select: none;\n\n  * {\n    user-select: none;\n  }\n\n  .octave-controls-wrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    border: 1px solid #ffffff2d;\n    border-right: none;\n    letter-spacing: -3px;\n  }\n\n  .octave-changer-label {\n    writing-mode: vertical-rl;\n    text-orientation: upright;\n    text-align: center;\n    width: 20px;\n  }\n\n  .octave-changer {\n    display: flex;\n    flex-direction: column;\n    margin-right: 6px;\n\n    button:nth-child(1) {\n      margin-bottom: 1px;\n    }\n\n    button:nth-child(2) {\n      margin-top: 2px;\n    }\n\n    button {\n      flex: 1;\n      width: 40px;\n      font-size: 20px;\n    }\n  }\n\n  .octave-offset-display {\n    display: flex;\n    justify-content: center;\n    font-weight: bold;\n    border-top: 1px solid #ffffff2d;\n    margin-top: 3px;\n    padding-right: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
