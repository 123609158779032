// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --highlight: #7f7caf;
  --highlight-1: #15c960;
  --highlight-2: #419282;
  --highlight-3: #e0d344;
  --highlight-4: #ce463f;
  --highlight-5: #1a242c;
}
`, "",{"version":3,"sources":["webpack://./src/colors.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;AACxB","sourcesContent":[":root {\n  --highlight: #7f7caf;\n  --highlight-1: #15c960;\n  --highlight-2: #419282;\n  --highlight-3: #e0d344;\n  --highlight-4: #ce463f;\n  --highlight-5: #1a242c;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
