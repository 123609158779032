// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  display: flex;
}

h1 {
  text-align: center;
}

hr {
  color: #ddd;
  width: 100%;
}

.browser-not-supported {
  background-color: #343434;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0px 16px 16px 16px;
  font-family:
    Menlo,
    Monaco,
    Lucida Console,
    Liberation Mono,
    DejaVu Sans Mono,
    Bitstream Vera Sans Mono,
    Courier New,
    monospace,
    serif;
  width: 80vw;

  code {
    font-size: 17.5px;
    background-color: #3f3f3f;
    padding: 2px 4px 2px 4px;
    border-radius: 0.2em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/misc/BrowserNotSupported.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,2BAA2B;EAC3B;;;;;;;;;SASO;EACP,WAAW;;EAEX;IACE,iBAAiB;IACjB,yBAAyB;IACzB,wBAAwB;IACxB,oBAAoB;EACtB;AACF","sourcesContent":["body {\n  display: flex;\n}\n\nh1 {\n  text-align: center;\n}\n\nhr {\n  color: #ddd;\n  width: 100%;\n}\n\n.browser-not-supported {\n  background-color: #343434;\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n  padding: 0px 16px 16px 16px;\n  font-family:\n    Menlo,\n    Monaco,\n    Lucida Console,\n    Liberation Mono,\n    DejaVu Sans Mono,\n    Bitstream Vera Sans Mono,\n    Courier New,\n    monospace,\n    serif;\n  width: 80vw;\n\n  code {\n    font-size: 17.5px;\n    background-color: #3f3f3f;\n    padding: 2px 4px 2px 4px;\n    border-radius: 0.2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
