// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.safari-notification {
  position: sticky;
  top: 0;
  background-color: #ffd966;
  color: #333;
  padding: 4px 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 1000;
  border-bottom: 2px solid #e69138;
  line-height: 1.5;
  text-align: center;
  margin-top: -2px;
}

.safari-notification-message {
  flex: 1 1 70%;
  margin: 0;
  font-size: 1.15em;
}

.safari-notification-button {
  flex: 1 1 30%;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
}

.safari-notification-button:hover {
  background-color: #555;
}

@media (min-width: 600px) {
  .safari-notification {
    flex-wrap: nowrap;
  }

  .safari-notification-button {
    margin-top: 0;
    max-width: 150px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/misc/SafariNotification.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,gCAAgC;EAChC,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB;AACF","sourcesContent":[".safari-notification {\n  position: sticky;\n  top: 0;\n  background-color: #ffd966;\n  color: #333;\n  padding: 4px 24px;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  z-index: 1000;\n  border-bottom: 2px solid #e69138;\n  line-height: 1.5;\n  text-align: center;\n  margin-top: -2px;\n}\n\n.safari-notification-message {\n  flex: 1 1 70%;\n  margin: 0;\n  font-size: 1.15em;\n}\n\n.safari-notification-button {\n  flex: 1 1 30%;\n  margin-top: 10px;\n  padding: 10px 15px;\n  background-color: #333;\n  color: #fff;\n  border: none;\n  cursor: pointer;\n  text-align: center;\n}\n\n.safari-notification-button:hover {\n  background-color: #555;\n}\n\n@media (min-width: 600px) {\n  .safari-notification {\n    flex-wrap: nowrap;\n  }\n\n  .safari-notification-button {\n    margin-top: 0;\n    max-width: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
